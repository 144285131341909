<template>
  <div class="modal-mask" @click="$emit('close')">
    <div class="flex h-screen items-center justify-center">
      <div
        class="max-w-xl shadow m-auto p-2 bg-white flex flex-col max-h-screen"
        @click.stop=""
      >
        <div v-if="$slots.header" class="mb-2 flex-shrink-0">
          <slot name="header"></slot>
        </div>

        <div class="mb-2 overflow-y-auto">
          <slot>
            default body
          </slot>
        </div>

        <hr class="border-gray-300" />
        <div class="text-right mt-2 flex-shrink-0">
          <slot name="footer">
            <button class="modal-default-button" @click="$emit('close')">
              Schließen
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal"
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-default-button {
  @apply bg-pacific-600;
  @apply py-1;
  @apply px-3;
  @apply text-white;
}
.modal-default-button:focus {
  @apply outline-none;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
