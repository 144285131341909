<template>
  <div class="mx-2">
    <div class="flex flex-col items-center justify-center mb-8">
      <!--
      <h1
        class="text-6xl header text-center text-gray-700 font-bold mb-8 text-shadow"
      >
        Josis Imbiss
      </h1>
      -->
      <img
        class="logo rounded-full ring-4 ring-pacific-700 ring-offset-gray-700 ring-offset-4"
        width="200"
        height="200"
        src="../assets/logo.jpg"
        alt="Logo"
      />
      <h1 class="mt-4 text-6xl text-white font-semibold">Josis Imbiss</h1>
    </div>
    <div class="home">
      <a
        href="#bestellen"
        class="inline-block text-center text-white text-lg font-semibold bg-pacific-500 hover:bg-pacific-600 p-2 w-full mb-8"
      >
        Hier klicken zum bestellen ➞
      </a>

      <Menu />
    </div>

    <div id="bestellen" class="mb-36">
      <div
        class="rounded-t h-8 bg-pacific-600 px-2 py-6 flex items-center justify-center text-white font-semibold text-xl"
      >
        Bestellen
      </div>
      <div class="rounded-b p-2 bg-white">
        <div class="border border-dashed border-red-300 rounded mb-2 p-2">
          <p class="text-red-500 text-center text-2xl font-semibold">
            Info zu Corona:
          </p>
          <p class="text-red-500 text-center font-semibold leading-5">
            Aktuell ist nur<br />
            "Vorbestellung für Selbstabholer"<br />
            verfügbar.
          </p>
        </div>
        <div class="border border-gray-300 rounded">
          <h2 class="text-center font-semibold text-2xl mb-4">
            Vor Ort
          </h2>
          <div class="text-center text-lg">
            <span>
              Schloßstraße 16<br />
              17159 Dargun<br />
              <br />
              Täglich geöffnet, auch an Sonn- und Feiertagen<br />
              von 11:00 bis 21:00 Uhr
            </span>
          </div>
        </div>

        <p class="text-center font-semibold my-4">
          - ODER -
        </p>

        <div class="border border-gray-300 rounded">
          <h2 class="text-center font-semibold text-2xl mb-4">
            Telefonisch Vorbestellen<br />
            für Selbstabholer
          </h2>
          <div class="md:grid grid-cols-2 text-center">
            <div>
              <span class="font-semibold text-lg">Telefonnummer</span>
              <p>+49 39959 364977</p>
              <HouseNumber width="100%" />
            </div>
            <div>
              <span class="font-semibold text-lg">Mobilnummer</span>
              <p>+49 147 8447828</p>
              <MobileNumber />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="navigationButtons">
    <a href="#bestellen" class="inline-block mb-2">Bestellen</a>
    <div v-on:click="backToTop">Nach oben</div>
  </div>

  <footer class="text-sm bg-pacific-700 px-3 py-1 text-white">
    Inhaber: Kalender Dogan<br />
    Copyright &copy; Cedric Schmitt. Alle Rechte vorbehalten.
  </footer>
</template>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu.vue";
import HouseNumber from "../assets/house.svg";
import MobileNumber from "../assets/mobile.svg";

export default {
  name: "Home",
  components: {
    Menu,
    HouseNumber,
    MobileNumber
  },
  created: function() {
    window.onscroll = function() {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        document.getElementById("navigationButtons").style.display =
          "inline-block";
      } else {
        document.getElementById("navigationButtons").style.display = "none";
      }
    };
  },
  methods: {
    backToTop: function() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
};
</script>

<style scoped>
.header {
  font-family: "Quicksand", sans-serif;
}
.text-shadow {
  text-shadow: 0px 2px 1px rgba(10, 10, 10, 1);
}
#navigationButtons {
  display: none;
  position: fixed;
  z-index: 99;
  text-align: right;
  @apply right-1;
  @apply bottom-1;
}
#navigationButtons > div,
a {
  @apply cursor-pointer;
  @apply bg-pacific-400;
  @apply text-white;
  @apply py-2;
  @apply px-4;
}
</style>
