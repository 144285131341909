<template>
  <div class="menu-item">
    <div class="p-2 flex">
      <div class="mr-1">
        <span
          v-if="menuItem.number"
          class="block text-gray-500 number font-light"
        >
          #{{ menuItem.number.toString().padStart(3, "0") }}
        </span>

        <button
          v-on:click="open = !open"
          class="inline-block px-4 font-semibold text-gray-500 rounded bg-gray-200 focus:outline-none"
        >
          i
        </button>
      </div>

      <div class="flex-1">
        <div class="flex">
          <p class="flex-1">
            {{ menuItem.name }}
          </p>
        </div>
        <small class="text-sm mr-1">{{ menuItem.extra }}</small>
      </div>

      <div class="prices flex flex-col">
        <price
          v-for="(price, index) in menuItem.prices"
          :key="index"
          :price="price"
          :name="priceNames[index]"
        />
        <div class="flex justify-center h-5">
          <button
            @click.prevent="removeFromBasket"
            class="px-1 text-white bg-pacific-600 hover:bg-pacific-700 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              width="18px"
              height="18px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 13H5v-2h14v2z" />
            </svg>
          </button>
          <span class="px-2">{{ basketCount }}</span>
          <button
            @click.prevent="addToBasket"
            class="px-1 text-white bg-pacific-600 hover:bg-pacific-700 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              width="18px"
              height="18px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div v-if="open" class="border-t border-gray-200 p-2 text-sm">
      Allergene: {{ getAllergens() }}<br />
      Zusatzstoffe: {{ getAdditives() }}
    </div>
  </div>
</template>

<script>
import Price from "@/components/Price.vue";

export default {
  components: { Price },
  name: "MenuItem",
  props: ["menuItem", "priceNames"],
  data: function() {
    return {
      open: false,
      allergensConversionTable: {
        e: "Ei",
        f: "Fisch",
        g: "Glutenhaltiges Getreide",
        k: "Krebstiere",
        m: "Milch (Laktose)",
        s: "Sellerie",
        w: "Weichtiere",
        x: "Senf",
        y: "Sesam",
        z: "Soja"
      },
      additivesConversionTable: {
        1: "Süßstoffe",
        2: "Antioxydationsmittel",
        3: "Phosphate",
        4: "Nitrit / Nitrat"
      }
    };
  },
  computed: {
    basketCount: function() {
      let count = this.$store.state.basket[this.menuItem.number];

      return count ?? 0;
    }
  },
  methods: {
    getPrices: function() {
      const prices = this.menuItem.prices;
      const priceNames = this.priceNames;

      let text = "";
      for (let i = 0; i < prices.length; i++)
        text += priceNames[i] + ": " + prices[i];

      return text;
    },
    getAllergens: function() {
      let allergens = [];

      if (this.menuItem.contents[1]) {
        allergens = this.convertArray(
          this.menuItem.contents[1],
          this.allergensConversionTable
        );
      }

      if (allergens.length === 0) {
        allergens = ["Keine"];
      }

      return allergens.join(", ");
    },
    getAdditives: function() {
      let additives = [];

      if (this.menuItem.contents[0]) {
        additives = this.convertArray(
          this.menuItem.contents[0],
          this.additivesConversionTable
        );
      }

      if (additives.length === 0) {
        additives = ["Keine"];
      }

      return additives.join(", ");
    },
    convertArray: function(array, conversionTable) {
      let converted = [];

      array.forEach(item => {
        converted.push(conversionTable[item]);
      });

      return converted;
    },
    removeFromBasket: function() {
      this.$store.commit("removeFromBasket", this.menuItem.number);
    },
    addToBasket: function() {
      this.$store.commit("addToBasket", this.menuItem.number);
    }
  }
};
</script>

<style scoped>
.number {
  font-family: monospace, sans-serif;
}
</style>
