<template>
  <div class="container mx-auto max-w-4xl mt-8">
    <router-view />
  </div>
</template>

<style>
body,
html {
  @apply bg-gray-700;
}
</style>
