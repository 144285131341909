<template>
  <button
    href="#"
    class="fixed shadow top-1 right-1 text-xl p-3  bg-pacific-400 hover:bg-pacific-500 z-40 focus:outline-none"
    v-on:click="showModal = true"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="white"
      width="18px"
      height="18px"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M17.21 9l-4.38-6.56c-.19-.28-.51-.42-.83-.42-.32 0-.64.14-.83.43L6.79 9H2c-.55 0-1 .45-1 1 0 .09.01.18.04.27l2.54 9.27c.23.84 1 1.46 1.92 1.46h13c.92 0 1.69-.62 1.93-1.46l2.54-9.27L23 10c0-.55-.45-1-1-1h-4.79zM9 9l3-4.4L15 9H9zm3 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
      />
    </svg>
  </button>

  <modal v-if="showModal" @close="showModal = false">
    <menu-category
      v-for="category in data"
      :key="category.id"
      :category="category"
    />

    <span class="text-gray-800 text-lg italic" v-if="data.length === 0">
      Noch nichts gespeichert!<br />
      Füge doch etwas hinzu...
    </span>

    <!--
    <div v-else>
      <div class="flex justify-end">
        <span class="mr-2">Gesamt:</span>
        ~<price :price="orderTotal" :name="null" />
      </div>
      <div class="text-right">
        <small>
          Gesamt-Preis wird bei mehr-preisigen Artikeln am günstigsten Preis
          festgemacht
        </small>
      </div>
    </div>
    -->

    <template v-slot:footer>
      <div class="text-left grid grid-cols-2 space-x-2 justify-around">
        <div class="">
          <span class="font-semibold text-lg">Telefonnummer</span>
          <p>+49 39959 364977</p>
        </div>
        <div>
          <span class="font-semibold text-lg">Mobilnummer</span>
          <p>+49 147 8447828</p>
        </div>
      </div>

      <hr class="border-gray-300 my-2" />

      <button class="modal-default-button mr-2" @click="emptyBasket">
        Leeren
      </button>
      <button class="modal-default-button" @click="showModal = false">
        Schließen
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import MenuCategory from "@/components/MenuCategory.vue";

export default {
  name: "OrderList",
  props: ["menu"],
  components: {
    Modal,
    MenuCategory
  },
  data: function() {
    return {
      showModal: false,
      orderTotal: 0
    };
  },
  computed: {
    data: function() {
      let basket = this.$store.state.basket;
      let data = [];

      for (let category of this.menu) {
        let c = Object.assign({}, category);
        c.menu = category.menu.filter(i => basket[i.number]);
        c.menu.forEach(menuItem => {
          this.orderTotal += menuItem.prices[0];
        });

        if (c.menu.length > 0) {
          data.push(c);
        }
      }

      return data;
    }
  },
  methods: {
    emptyBasket: function() {
      this.$store.state.basket = [];
    }
  }
};
</script>
