import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: function() {
    return {
      basket: [],
      vegetarian: false
    };
  },
  mutations: {
    addToBasket(state, item) {
      if (state.basket[item]) {
        state.basket[item] = state.basket[item] + 1;
      } else {
        state.basket[item] = 1;
      }
    },
    removeFromBasket(state, item) {
      if (state.basket[item]) {
        state.basket[item] = state.basket[item] - 1;

        if (state.basket[item] === 0) {
          let l = state.basket.length;
          while (l--) {
            if (state.basket[l] === item) {
              state.basket.splice(l, 1);
              break;
            }
          }
        }
      }
    },
    setVegetarian(state, value) {
      state.vegetarian = value;
    }
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()]
});
